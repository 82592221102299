class PartnerInfoModel {
  constructor() {
    this.companyName = ""; // 회사 이름
    this.corporateNum = ""; // 사업자 번호
    this.userNum = 0; // 사용자 번호
  }

  setData(obj) {
    if (obj.companyName) this.companyName = obj.companyName;
    if (obj.corporateNum) this.corporateNum = obj.corporateNum;
    if (obj.userNum) this.userNum = obj.userNum;
  }

  getData() {
    let obj = {
      companyName: this.companyName,
      corporateNum: this.corporateNum,
      userNum: this.userNum,
    };

    return obj;
  }
}

export default PartnerInfoModel;
